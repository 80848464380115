import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'app-drag-drop-file',
    templateUrl: './drag-drop-file.component.html',
    styleUrls: ['./drag-drop-file.component.scss'],
    standalone: false
})
export class DragDropFileComponent  implements OnInit{

  showDiv: boolean | false | undefined;
  fileLoading: boolean=false;
  dragEnter: boolean= false;
  @Input() files: any[] = [];
  @Output() onUpload = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  onFileDropped(event: any) {
    this.prepareFilesList(event);
  }
  fileBrowseHandler(files: any) {
    this.showDiv=true;
    this.prepareFilesList(files.target.files);
  }
  // deleteFile(index: number,id: number) {
  //   this.files.splice(index, 1);
  //   this.onDelete.emit(id)
  // }
  downloadFile(){


  }

  ngOnInit() {
    console.log('files',this.files)
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.onUpload.emit(item);
      item.progress = 0;
      this.files.push(item);
    }
    console.log("#53",files)
    this.uploadFilesSimulator(0);

  }
  formatBytes(bytes: any, decimals?: any) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  handleEvent(e: any) {
    e.preventDefault();
    if(e.type==='drop') {
      // console.log("#118",e.type);
      // this.onDragEnter(event as DragEvent);
      this.dragEnter=true;
      console.log("#139",e.dataTransfer);
        const files = Array.from(e.dataTransfer.files);
        this.prepareFilesList(files);
        e.dataTransfer.clearData();
        // this.onFileDropped(e);

        this.dragEnter = false;
        console.log(e.type,'dropfile')
    }

    switch (e.type) {
      case 'dragleave':
        this.dragEnter = false;
        console.log("#118",e.type);
        break;
      case 'dragover':
        this.dragEnter = true;
        console.log("#118",e.type);
        break;
    }

  }
  onDragOver(event: DragEvent) {

    event.preventDefault();
    // this.handleEvent(event);
  }


  onDrop(e: any) {
    if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files);
      this.prepareFilesList(files);
      e.dataTransfer.clearData();
      this.onFileDropped(e);
      this.dragEnter = false;
      console.log(e.type,'dropfile')
    }


  }

  onFileDragEnter() {
    this.dragEnter = true;
  }

  onFileDragLeave() {
    this.dragEnter = false;
  }

  onFileDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onFileDrop(e: DragEvent) {
    e.preventDefault();
    this.dragEnter = false;

    if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
      const files = Array.from(e.dataTransfer.files);
      console.log('FILES',files)
      this.prepareFilesList(files);
      e.dataTransfer.clearData();
      this.dragEnter = false;
      this.files = files; // Assign the files to this.files

      this.onFileDropped(e);
    }

  }



}

