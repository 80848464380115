import * as i0 from '@angular/core';
import { SecurityContext, ElementRef, ChangeDetectorRef, Component, Inject, Input, NgModule, Optional } from '@angular/core';
import * as i2 from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
const _c0 = ["*"];
class Icons {
  constructor(icons) {
    this.icons = icons;
  }
}
function uppercamelcase(str) {
  return str.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, firstLetter => {
    return firstLetter.toUpperCase();
  }).replace(/[-_]/g, '');
}
class FeatherComponent {
  constructor(elem, changeDetector, icons, sanitizer) {
    this.elem = elem;
    this.changeDetector = changeDetector;
    this.icons = icons;
    this.sanitizer = sanitizer;
  }
  ngOnChanges(changes) {
    // icons are provided as an array of objects because of "multi: true"
    const icons = Object.assign({}, ...this.icons);
    const svg = icons[uppercamelcase(changes.name.currentValue)] || '';
    if (!svg) {
      console.warn(`Icon not found: ${changes.name.currentValue}\n` + `Refer to documentation on https://github.com/michaelbazos/angular-feather`);
    }
    // Since the icons are precompiled we can trust them as safe html.
    this.elem.nativeElement.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(svg));
    this.changeDetector.markForCheck();
  }
}
FeatherComponent.ɵfac = function FeatherComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FeatherComponent)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(Icons), i0.ɵɵdirectiveInject(DomSanitizer));
};
FeatherComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FeatherComponent,
  selectors: [["i-feather"], ["feather-icon"]],
  inputs: {
    name: "name"
  },
  standalone: false,
  features: [i0.ɵɵNgOnChangesFeature],
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function FeatherComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  styles: ["[_nghost-%COMP%]{display:inline-block;width:24px;height:24px;fill:none;stroke:currentColor;stroke-width:2px;stroke-linecap:round;stroke-linejoin:round}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FeatherComponent, [{
    type: Component,
    args: [{
      selector: 'i-feather, feather-icon',
      templateUrl: './feather.component.html',
      styleUrls: ['./feather.component.scss']
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: Icons,
      decorators: [{
        type: Inject,
        args: [Icons]
      }]
    }, {
      type: i2.DomSanitizer,
      decorators: [{
        type: Inject,
        args: [DomSanitizer]
      }]
    }];
  }, {
    name: [{
      type: Input
    }]
  });
})();
class FeatherModule {
  constructor(icons) {
    this.icons = icons;
    if (!this.icons) {
      throw new Error(`No icon provided. Make sure to use 'FeatherModule.pick({ ... })' when importing the module\n` + `Refer to documentation on https://github.com/michaelbazos/angular-feather`);
    }
  }
  static pick(icons) {
    return {
      ngModule: FeatherModule,
      providers: [{
        provide: Icons,
        multi: true,
        useValue: icons
      }]
    };
  }
}
FeatherModule.ɵfac = function FeatherModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FeatherModule)(i0.ɵɵinject(Icons, 8));
};
FeatherModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FeatherModule
});
FeatherModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FeatherModule, [{
    type: NgModule,
    args: [{
      declarations: [FeatherComponent],
      exports: [FeatherComponent]
    }]
  }], function () {
    return [{
      type: Icons,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/*
 * Public API Surface of angular-feather
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FeatherComponent, FeatherModule };
