import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgStyle} from "@angular/common";

@Component({
  selector: 'app-loader-button',
  templateUrl: './loader-button.component.html',
  styleUrls: ['./loader-button.component.scss'],
  standalone: true,
  providers: [],
  imports: [
    NgStyle
  ]
})
export class LoaderButtonComponent {
  @Input()
  isLoading: boolean = false;
  @Input()
  disabled: boolean = false;
  @Input()
  customButtonClass?: string;
  @Input()
  wrapperClass?: string;
  @Input()
  loaderColor: any = "#ffffff";
  @Input()
  buttonText: string = '';
  @Output()
  onClick = new EventEmitter<any>();

  onButtonClick(): void {
    this.onClick.emit();
  }

}
