import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {debounceTime, delay, distinctUntilChanged, filter, map, Subject, switchMap, takeUntil, tap} from "rxjs";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {FormControl} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
    selector: 'app-custom-serach-dropdown',
    templateUrl: './custom-serach-dropdown.component.html',
    styleUrls: ['./custom-serach-dropdown.component.css'],
    standalone: false
})
export class CustomSerachDropdownComponent implements OnInit{
  @Input() parentModalHeight?: any;
  @Input() required?: boolean = true;
  @Input() label: string | undefined;
  @Input() items: any[] = [];
  @Input() activeClass: string = '';
  @Input() selectedItem?: string | any;
  @Output() searchQuery = new EventEmitter<string>();
  @Output() selectedValue = new EventEmitter<any>();
  @Output() clearSelectedValue = new EventEmitter<any>();
  formServerCtrl = new FormControl();
  searchText: string | undefined
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger | any;
  openSuggestionBox: boolean = false;
  @ViewChild("insideElement") insideElement: { nativeElement: { contains: (arg0: any) => any; }; } | any;
  @ViewChild('closeIcon') closeIcon: { nativeElement: { contains: (arg0: any) => any; }; } | any;
  @ViewChild('listContainer') listContainer:{ nativeElement: { contains: (arg0: any) => any; }; } | any;
  @ViewChild('formInput') formInput: ElementRef | any;
  selectedItemIndex: number = 0;
  scrollIndex: number = 0;
  dataSpinner: boolean = false;
  hasValue: boolean = false;
  dynamicHieght!: number;
  protected _onDestroy = new Subject<void>();
  constructor(
    public router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent) : void {
      const clickedInside = this.insideElement.nativeElement.contains(event.target);
      const clickedCloseButton = (event.target as Element).id === 'closeButton';
      if (!clickedInside) {
        this.openSuggestionBox = false;
      } else if (clickedCloseButton) {
        this.selectedItem = null;
        this.selectedValue.emit(null);
        this.openSuggestionBox = true;
        this.cdr.detectChanges();
        this.formInput.nativeElement.focus();
      } else {
        this.openSuggestionBox = true;
        this.cdr.detectChanges();
        this.formServerCtrl.setValue(this.searchText);
        this.formInput.nativeElement.focus();
    }
  };

  @HostListener('keydown', ['$event']) public onKeydownHandler(event: KeyboardEvent) : void  {
    if (event.key === "Escape") {
      this.openSuggestionBox = false;
    }
  };

  ngOnInit() {
    this.onSearchItem();
  }

  ngAfterViewInit() {
    const insideElement = this.insideElement.nativeElement.getBoundingClientRect().bottom;
    const spaceBelowDiv = Math.round(this.parentModalHeight.modalHeight - (insideElement - this.parentModalHeight.modalTop) - 25);
    if(spaceBelowDiv > 200) {
      this.dynamicHieght = 200;
    } else {
      this.dynamicHieght = spaceBelowDiv;
    }
  }

  onKeyDown(event: KeyboardEvent) : void {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (this.selectedItemIndex > 0) {
          this.selectedItemIndex--;
          this.scrollSelectedSuggestionIntoView('center');
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (this.selectedItemIndex < this.items.length - 1) {
          this.selectedItemIndex++;
          this.scrollSelectedSuggestionIntoView('start');
        }
        break;
      case 'Enter':
        event.preventDefault();
        if (this.selectedItemIndex >= 0) {
          this.selectedValue.emit(this.items[this.selectedItemIndex]);
          this.selectedItem = this.items[this.selectedItemIndex].name;
          this.resetAllIndexScroll();
          this.formServerCtrl.setValue('');
          this.openSuggestionBox = false;
        }
        break;
    }
  }

  resetAllIndexScroll() : void {
    this.scrollIndex = 0;
    this.selectedItemIndex = 0;
  }

  scrollSelectedSuggestionIntoView(position: ScrollLogicalPosition) : void {
    const activeItem = document.querySelector('.selectedCountry') as HTMLLIElement;
    if (activeItem) {
      activeItem.scrollIntoView({
        behavior: 'smooth',
        block: position,
        inline: 'center'
      });
    }
  }

  customSelectedItem(item: any): void {
    this.selectedValue.emit(item);
    this.selectedItem = item.name ?? item.title;
    this.formServerCtrl.setValue('');
    this.openSuggestionBox = false;
  }

  clearSelected():void {
    this.selectedItem = null;
    this.selectedValue.emit(null);
  }

  clearInput() : void {
    const textInputElement = this.formInput.nativeElement as HTMLInputElement;
    if(textInputElement.value) {
      this.searchText = '';
      textInputElement.value = this.searchText;
      this.hasValue = false;
      this.searchQuery.emit(this.searchText);
    }
  }

  onSearchItem() {
    this.formServerCtrl?.valueChanges.pipe(
      tap((searchValue) => {
        if (searchValue && searchValue.trim() !== '') {
          this.dataSpinner = true;
        }
      }),
      debounceTime(500),
      distinctUntilChanged(),
      tap((searchValue) => {
        this.hasValue = true;
        this.searchText = searchValue;
        this.searchQuery.emit(searchValue);
      })
    ).subscribe({
      next: () => {
        this.dataSpinner = false;
      },
      error: err => {
        console.error(err);
        this.dataSpinner = false;
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
