import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../services/api.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DataService} from "../../services/data.service";
import {DraggingService} from 'src/app/services/candidate/dragging.service';
import {DeletePopupComponent} from "../../delete-popup/delete-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {NgToastService} from "ng-angular-popup";

@Component({
    selector: 'app-comments',
    templateUrl: './comments.component.html',
    styleUrls: ['./comments.component.css'],
    standalone: false
})
export class CommentsComponent implements OnInit {
  @ViewChild('commentTextarea') commentTextarea!: ElementRef<HTMLTextAreaElement>;
  @Input() currentUserId?: string;
  @Input() candidateId: string | any;
  @Input() currentStatus!: string;
  @Input()
  expandTextarea = false;
  userId?: string | undefined;
  comments: any[] = [];
  userIdOfCurrentUser: any;
  commentLength!: number;
  commentForm: FormGroup | any;
  commentEditForm: FormGroup | any;
  editFeedback: boolean = false;
  showCommentText: boolean = false;
  commentId: any;
  idofEmp: any;
  isDropdownOpen: boolean = false;
  selectedCommentId: string | undefined;
  private errorMessage: string | any;

  constructor(private service: ApiService,
              private fb: FormBuilder,
              private data: DataService,
              public dialog: MatDialog,
              private route: Router,
              public toastr: NgToastService,
              public _draggingService: DraggingService) {
  }

  ngOnInit(): void {

    this.data.empProfile$.subscribe({
      next: (emp: any) => {
        this.idofEmp = emp?.id;
      }
    });
    this.data.isLoading.next(true);
    this.commentForm = this.fb.group({
      text: ['', Validators.required],
    });
    this.commentEditForm = this.fb.group({
      text: ['', Validators.required],
    });
    this.getCandidateFeedback();

  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen; // Toggle dropdown visibility
  }

  getDevices(userId: any) {
    this.data.isLoading.next(true);
    this.service.getDevice(userId).subscribe(res => {
      this.userIdOfCurrentUser = res.user.id;
    });
    this.data.isLoading.next(false);
  }

  onSubmit() {

    this.service.updateCandidateFeedback(this.candidateId, this.commentForm.value).subscribe({
      next: res => {
        this.getCandidateFeedback();
      }
    })
  }

  getCandidateFeedback() {
    this.getDevices(this.idofEmp);
    this.data.isLoading.next(true);
    this.comments = [];
    this.service.getCandidateFeedback(this.candidateId).subscribe({
      next: res => {
        res.map((re: any) => this.comments.push(re));
        if (res.length > 0) {
          this.commentLength = res.length;
          this.commentId = res.id;
          // this._draggingService.updateCoummentCount(this.candidateId, this.currentStatus, res.length);
          this._draggingService.updateAllCandidate(this.candidateId, this.currentStatus, res.name, res.email, res.designation, res.experience, res.length);
        }
        this.data.isLoading.next(false);
      }

    })
    this.commentForm.reset();
    this.data.isLoading.next(false);
  }

  cancelButton() {
    this.commentForm.reset();
    this.expandTextarea = false;
  }

  deleteComment(action: string, commentId?: any): void {
    this.isDropdownOpen = false;

    if (action === 'delete') {
      const dialogRef1 = this.dialog.open(DeletePopupComponent, {
        data: {
          title: 'Alert',
          message: 'Are you sure you want to delete this comment ',
          type: 'Delete'
        }
      });
      dialogRef1.afterClosed().subscribe((res: any) => {
        if (res) {

          this.service.deleteCommentById(commentId).subscribe(data => {
            this.toastr.success("Success message", "Comment is Delete Successfully ", 5000);
            this.getCandidateFeedback();
            this.data.isLoading.next(false);
          });
        }
      })
    }

  }

  editFeedbackComment(id: string) {
    this.selectedCommentId = id;
    this.editFeedback = true;
    this.showCommentText = true;
    this.isDropdownOpen = false;
  }

  editComment(commentId?: any): void {
    this.service.updateComment(this.commentEditForm.value, commentId).subscribe({
      next: res => {
        if (res) {
          console.log(res);
        }
      }, error: err => {
        this.toastr.danger(err.error.reason, "Failed", 9000,);
        console.log(err)
      },
      complete: () => {
        this.getCandidateFeedback();
        this.editFeedback = false;

      }
    })
  }

  cancelEditComment() {
    this.editFeedback = false;
  }

  focusTextarea() {
    if (!this.expandTextarea) {
      this.expandTextarea = true;
      setTimeout(() => {
        this.commentTextarea.nativeElement.focus();
      });
    }
  }
}
