import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoaderButtonComponent} from "./loader-button/loader-button.component";
import { CustomSerachDropdownComponent } from './custom-serach-dropdown/custom-serach-dropdown.component';
import {ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {CommentsComponent} from "./comments/comments.component";
import { DragDropFileComponent } from './drag-drop-file/drag-drop-file.component';
import {DragDropDirective} from "./drag-drop-file/drag-drop.directive";
import {FeatherModule} from "angular-feather";
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  FullscreenOverlayContainer,
  OverlayContainer
} from "@angular/cdk/overlay";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MatMenuModule} from "@angular/material/menu";



@NgModule({
  declarations: [
    CustomSerachDropdownComponent,
    CommentsComponent,
    DragDropFileComponent,
    DragDropDirective,
    CustomSerachDropdownComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    FeatherModule,
    ScrollingModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    MatMenuModule,
    LoaderButtonComponent,
  ],
  providers: [{provide: OverlayContainer, useClass: FullscreenOverlayContainer}],
  exports: [
    LoaderButtonComponent,
    CustomSerachDropdownComponent,
    CommentsComponent,
    DragDropFileComponent,
    CustomSerachDropdownComponent,
  ]
})
export class UtilModule { }
