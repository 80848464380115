import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DataService} from "../services/data.service";
import {ToasterPosition} from "ng-angular-popup";

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css'],
    standalone: false
})
export class LayoutComponent implements OnInit{
  isLoading=false;
  user: any;
  sidebarClass_1: any;
  constructor(public data: DataService,private cdRef:ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.data.isLoading.subscribe(res=>{
      this.isLoading=res;
      this.cdRef.detectChanges();
    });
    this.data.empProfile$.subscribe({
      next: (user: any)=> {
        this.user = user;
        this.data.isLoading.next(false);
      },
      error: err => {
        this.data.isLoading.next(false);
      },
      complete: () => {
        this.data.isLoading.next(false);
      }
    });
  }
  getSidebarClass (data: any) {
    this.sidebarClass_1 = data;
    return data;
  }

  protected readonly ToasterPosition = ToasterPosition;
}
