<div class=" {{wrapperClass}}">
  @if (!isLoading) {
    <button (click)="onButtonClick()" class="btn {{customButtonClass}}"
            type="submit" [disabled]="disabled">{{ buttonText }}
    </button>
  } @else {
    <button disabled class="btn {{customButtonClass}}" style="border-style: none">
      <div class="spinner-border" role="status" [ngStyle]="{'color': loaderColor}"></div>
    </button>
  }
</div>
