import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-delete-popup',
    templateUrl: './delete-popup.component.html',
    styleUrls: ['./delete-popup.component.css'],
    standalone: false
})
export class DeletePopupComponent {
  data: any;
  constructor(@Inject(MAT_DIALOG_DATA) public inputData: any, private dialogRef: MatDialogRef<DeletePopupComponent>) {
  }

  ngOnInit(): void {
    this.data = this.inputData;
  }
  close(data: any): void {
    this.dialogRef.close(data);
  }
}
