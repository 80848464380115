<div class="page-wrapper" xmlns="http://www.w3.org/1999/html">
  <div *ngIf="isLoading" class="z-index_high progress_bar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <app-header [user]="user" (sidebarClass)="getSidebarClass($event)"></app-header>

  <ng-container *ngIf="user" >
    <app-menu-navigation [userData]="user"></app-menu-navigation>
  </ng-container>
  <ng-toast [width]="300" [position]="ToasterPosition.TOP_RIGHT"></ng-toast>
  <router-outlet></router-outlet>

</div>
