import {Component, Input, OnInit,} from '@angular/core';
import {SessionManager} from "../../Shared/SessionManager";
import {Router} from "@angular/router";
import {DataService} from "../../services/data.service";
import {EmployeeProfile, User} from "../../Shared/Interfaces";
import {ApiService} from "../../services/api.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-menu-navigation',
    templateUrl: './menu-navigation.component.html',
    styleUrls: ['./menu-navigation.component.scss'],
    standalone: false
})
export class MenuNavigationComponent implements OnInit{
 userprofile:User[]=[];
  user: EmployeeProfile[] |any ;
  url='';
  empStatus:any;
  @Input() userData: any;

  constructor(private router: Router,
              private dataService: DataService,
              private apiService : ApiService,
              public dialog: MatDialog,
              public data: DataService,) {}

  ngOnInit(): void {

    this.user=SessionManager.getUser();
    this.userprofile=this.userData.profilePicture;
    this.router.events.subscribe((res:any)=>{
      this.url=res.url;
    });
    this.getStatus();
  }

  getStatus(){
    this.apiService.getEmpStatus(this.userData.id).subscribe({
        next: res => {
          this.empStatus = res?.status;
        },
        error: err => {
          console.log('err',err)
        },
        complete: () => {
          this.dataService.isLoading.next(false);
        }
      });
  }
}

